import React, { useEffect, useState } from 'react'
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApplication, getRegisters } from '../api';
import { getBusinessHeader } from '../config/header';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { setAuthenticate, setUser } from '../store/slices/user';



const Loginform = ({ border, borderColor, btnClick, token }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneOTPBtn, setPhoneOTPBtn] = useState(false);
    const customerToken = token

    const handlePhoneOTP = async () => {
        if (phoneNumber && customerToken) {
            setPhoneOTPBtn(true)
            axios.post(`/business/api/v1/otp/sendphone/${phoneNumber}`, "", getBusinessHeader(customerToken))
                .then(res => {
                    toast.success("OTP sent please check your mobile", { position: 'top-right' });
                }).catch(err => {
                    toast.error(err.response && err.response.data ? err.response.data.ErrorDetail : 'Already registered..!', { position: 'top-right' })
                })

        }
        else {
            toast.error('Mobile number is required..!', { position: 'top-right' })
        }
    }

    const formik = useFormik({
        initialValues: {
            countryCode: '+91',
            phone: '',
            phoneOTP: ''
        },
        validationSchema: Yup.object({
            countryCode: Yup.string(),
            phone: Yup.string().max(15).required("Mobile Number required"),
            phoneOTP: Yup.number().required("OTP is required")
        }),
        onSubmit: async (values, helpers) => {
            try {
                if (customerToken) {
                    const data = {
                        "phone_verify_otp": values.phoneOTP
                    }
                    await axios.post(`/business/api/v1/otp/verifyphone/${values.phone}`, data, getBusinessHeader(customerToken))
                        .then(async (res) => {
                            if (res.status === 200) {
                                const username = values.phone
                                const scope = 'user_type:customer'
                                const password = 'test123'

                                let bodyData = new FormData()
                                bodyData.append("grant_type", 'password')
                                bodyData.append("client_id", process.env.REACT_APP_CLIENT_ID)
                                bodyData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET)
                                bodyData.append("username", username)
                                bodyData.append("password", password)
                                bodyData.append("scope", scope)

                                const res = await axios.post('/business/api/v1/customers/auth/token', bodyData)
                                if (res) {
                                    let payload = {
                                        limit: 0,
                                        skip: 0,
                                        filter: `{"phone":"${username}"}`,
                                        token: res.data.access_token
                                    }

                                    const applicationRes = await getApplication(payload);
                                    if (applicationRes && applicationRes?.result && applicationRes.result.length > 0) {
                                        sessionStorage.setItem("ApplicaionData", JSON.stringify(applicationRes.result[0]))
                                        dispatch(setUser(applicationRes.result[0]))
                                        dispatch(setAuthenticate(true));
                                        navigate('/admission-form')
                                        setPhoneOTPBtn(false)
                                    }
                                    else {
                                        const registerResponse = await getRegisters(payload);
                                        if (registerResponse && registerResponse?.result && registerResponse.result.length > 0) {
                                            sessionStorage.setItem("ApplicaionData", JSON.stringify(registerResponse.result[0]))
                                            dispatch(setUser(registerResponse.result[0]))
                                            dispatch(setAuthenticate(true));
                                            navigate('/admission-form')
                                            setPhoneOTPBtn(false)
                                        }
                                        else {
                                            dispatch(setAuthenticate(true));
                                            navigate('/admission-form')
                                            setPhoneOTPBtn(false)
                                        }
                                    }

                                }
                            }
                        }).catch(err => {
                            toast.error(err.response && err.response.data ? err.response.data.ErrorDetail : 'Invalid OTP!', { position: 'top-right' })
                        })
                }

            } catch (err) {
                console.error('Error submitting form:', err);
                setPhoneOTPBtn(false)
                toast.error(err.response && err.response.data ? err.response.data.ErrorDetail : 'Register failed..!', { position: 'top-right' })
            }
        }

    })

    return (
        <div className={`bg-white opacity-[1] md:w-[420px]  md:mt-[30%] w-[90%] mx-auto p-[25px] rounded-md  ${border} ${borderColor}`}>
            <form onSubmit={formik.handleSubmit}>
                <div className='flex flex-row items-center justify-center'>
                    <h3 className='!text-2xl font-bold'>Log In</h3>
                </div>
                <div className='my-4 outline-0 w-full'>
                    <InputGroup>
                        <InputGroupText
                            className='w-[20%]'
                            name='countryCode'
                        >
                            +91
                        </InputGroupText>
                        <Input
                            className='w-[50%]'
                            type='tel'
                            name='phone'
                            value={formik.values.phone}
                            placeholder='Contact Number *'
                            onChange={(e) => {
                                formik.handleChange(e)
                                setPhoneNumber(e.target.value)
                            }}
                        />

                        <Button className='w-[30%]' type='button' onClick={handlePhoneOTP} disabled={phoneOTPBtn}>Verify - OTP</Button>
                    </InputGroup>
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.phone}</span>
                </div>
                <div className='mb-4 outline-0'>
                    <Input
                        type='text'
                        name='phoneOTP'
                        value={formik.values.phoneOTP}
                        placeholder='Phone OTP *'
                        onChange={formik.handleChange}
                    />
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.phoneOTP}</span>
                </div>
                <Button disabled={formik.isSubmitting}
                    className='bg-pink-500 text-richblack-5 w-40 h-[40px] d-flex justify-content-center align-items-center mx-auto mt-3'
                    type='submit'
                >
                    Login
                </Button>
                <div className='w-[70%] items-center justify-between text-center mx-auto mt-3'>
                    <h6><a href='/#' className='text-dark no-underline' onClick={btnClick}>New User</a></h6>
                </div>
            </form >
        </div >
    )
}

export default Loginform