import React from 'react';

const AboutUsForm = () => {
    return (
        <div className="max-w-4xl mx-auto px-4">
            <div className="py-8">
                <h1 style={{ fontWeight: 'bolder', color: '#484f8d' }}>About Us</h1><br />

                <h6>
                    <h6 className="text-lg mb-8">
                        Swamy Educational Trust, the managing trustee of Sri Ramanujar Engineering College (SREC). SREC was founded in 2002,
                        and is affiliated with Anna University and AICTE-approved, and offers Bachelor's and Master's programmes in engineering.
                        SREC, which is NAAC accredited and ISO 9001:2008 certified, enrols 240 students per year in a variety of specialisations.
                        Mrs. N. Vallikkannu founded the Trust in 1992 with the vision of meeting educational needs. Initially, the Trust focused on technical education through institutions such as Madras Industrial Technical Centre and Srimathi Lakshmiammal Memorial Matriculation Higher Secondary School. Sri Balaji Polytechnic College and Sri Ramanujar Engineering College were later established, representing excellence in technical education, particularly in Chennai.
                    </h6>
                    <h6 className="text-lg mb-8">
                        SREC is dedicated to becoming a premier institution, offering world-class education in Engineering, Technology, and Management. Our mission is to prepare students to create a positive societal impact within a cutting-edge learning environment, driven by a highly motivated faculty fostering innovation for comprehensive development.

                    </h6>
                    <h6 className="text-lg mb-8">
                        Our management actively monitors and reviews our quality performance through a robust quality management system, ensuring our commitment to providing accessible global education for all.                </h6>

                </h6>
            </div>

            <div className="py-8">
                <h2 className="text-2xl font-bold mb-4" style={{ color: '#707dd0' }}>Administration</h2><br />
                <ul className="list-disc pl-6 mb-4">

                    <li style={{ fontWeight: 'bold', fontSize: '20px' }}>Correspondent</li><br />
                    <div className="flex items-center">
                        <img
                            src={"/assets/corres.png"}
                            alt="Correspondent"
                            className="mr-4"
                        />
                        <p style={{ marginLeft: '40px', textAlign: 'justify', color: '#707dd0' }}>
                            Formal education must be broad and deep in the sciences and humanities. Then comes an increasing degree of specialization in the intricacies of a particular discipline, also involving continued post scholastic education. Normal promotion thus brings interdisciplinary activity as the engineer supervises a variety of specialists. Finally, the engineer enters into the management function, weaving people, money, materials, machines, and energy sources into completed processes for the use of society.
                        </p>
                    </div><br />

                    <li style={{ fontWeight: 'bold', fontSize: '20px' }}>Chairperson</li><br />
                    <div className="flex items-center">
                        <img src={"/assets/charperson.png"} alt="Correspondent" className="mr-4" />
                        <p style={{ marginLeft: '80px', textAlign: 'justify', color: '#707dd0' }}>I believe in hard work and dedication, I advice all our students to concentrate on their studies to complete the course on time with good academics to get offers from blue-chip companies. I welcome all our students to our campus to share the platform of knowledge. I believe in hard work and dedication, I advice all our students to concentrate on their studies to complete the course on time with good academics to get offers from blue-chip companies. I welcome all our students to our campus to share the platform of knowledge.</p>
                    </div><br />

                    <li style={{ fontWeight: 'bold', fontSize: '20px' }}>Secretary</li><br />
                    <div className="flex items-center">
                        <img src={"/assets/secretary.jpg"} alt="Correspondent" className="mr-4" />
                        <p style={{ marginLeft: '30px', textAlign: 'justify', color: '#707dd0' }}>
                            Our Institution can help you to achieve the success and professional satisfaction you want through unique combination of benefits. I encourage all the students who are interested in technology. We welcome you at our campus at Chennai to get the benefit of our quality education. Education is an opportunity. It's a door to knowledge, change and empower oneself. We offer our students only the very best, and encourage them to think, rather than memorize. To learn through innovative methods designed by our staff to stimulate and challenge young minds, and bring them to the forefront.
                        </p>
                    </div><br />

                    <li style={{ fontWeight: 'bold', fontSize: '20px' }}>Trustee</li><br />
                    <div className="flex items-center">
                        <img src={"/assets/trustee.png"} alt="Correspondent" className="mr-4" />
                        <p style={{ marginLeft: '70px', textAlign: 'justify', color: '#707dd0' }}>
                            I advice all our students to be strict and honest during their college days. Education is one of the best ways to achieve and fulfil your needs. My one and only request to our students is to understand well about their present situation and act accordingly to equip your selves to face the future competition.
                        </p>
                    </div><br />

                    <li style={{ fontWeight: 'bold', fontSize: '20px' }}>Director</li><br />
                    <p style={{ textAlign: 'justify', color: '#707dd0' }}>
                        Our Institution can help you to achieve the success and professional satisfaction you want through unique combination of benefits. I encourage all the students who are interested in technology. We welcome you at our campus at Chennai to get the benefit of our quality education. Education is an opportunity. It's a door to knowledge, change and empower oneself. We offer our students only the very best, and encourage them to think, rather than memorize. To learn through innovative methods designed by our staff to stimulate and challenge young minds, and bring them to the forefront.
                    </p><br />

                    <li style={{ fontWeight: 'bold', fontSize: '20px' }}>Dean</li><br />
                    <p style={{ textAlign: 'justify', color: '#707dd0' }}>
                        I believe in hard work and dedication, I advice all our students to concentrate on their studies to complete the course on time with good academics to get offers from blue-chip companies. I welcome all our students to our campus to share the platform of knowledge. I believe in hard work and dedication, I advice all our students to concentrate on their studies to complete the course on time with good academics to get offers from blue-chip companies. I welcome all our students to our campus to share the platform of knowledge.
                    </p><br />

                    <li style={{ fontWeight: 'bold', fontSize: '20px' }}>Principal</li><br />
                    <div className="flex items-center">
                        <img src={"/assets/principal.png"} alt="Correspondent" />
                        <p style={{ marginLeft: '50px', textAlign: 'justify', color: '#707dd0' }}>
                            Formal education must be broad and deep in the sciences and humanities. Then comes an increasing degree of specialization in the intricacies of a particular discipline, also involving continued post scholastic education. Normal promotion thus brings interdisciplinary activity as the engineer supervises a variety of specialists. Finally, the engineer enters into the management function, weaving people, money, materials, machines, and energy sources into completed processes for the use of society
                        </p>
                    </div>
                </ul>

            </div>
        </div>
    );
};

export default AboutUsForm;
