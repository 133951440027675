const reviews = [
    {
      id: 1,
      name: "Mrs.E. Geetha MBA",
      job: "Larsen Toubro Construction Limited, Executive Admin. (L&T)",
      image: "Geetha.png",
      text: "SREC, is an institute for gaining knowledge having good campus and excellent staff who supported in each and every step. It is one of the best colleges in Chennai, when it comes to teaching, personality development, training and Placements.",
    },
    {
      id: 2,
      name: "Mr.V.NETHAJI, MBA",
      job: "CITY UNION BANK, BRANCH DEVELOPMENT MANAGER",
      image: "NETHAJI.png",
      text: "It was my immense fortune to be the part of SREC where I can grow. The entire faculty and department leave no stone unturned to shape one's future. My two years at SREC have been a wonderful experience of learning with prolific exposure to outside. Huge respect, love and devotion for entire faculty members and department. It's their efforts that make me to count myself into better professionals.",
    },
    {
      id: 3,
      name: "Mr. GOWTHAM BALAKRISHNAN",
      job: "MECHANICAL ENGINEERING",
      image: "GOWTHAM.png",
      text: "I work as Senior Engineer - Projects, Flowserve India Controls Pvt. Ltd., SREC has competent and capable faculty, who are proactively involved to provide the very best for their students. Tie-ups with many prominent companies and provide software courses like CREO, ANSYS which are required for the industry. One of the best college, with complete exposure to different people.",
    },
  ];
  
  export default reviews;
  