import React from 'react'
import ContactUsform from './contactUs'

const CollegeAddressContact = () => {
    return (
        <div className='flex flex-col w-10/12 mx-auto !pb-[50px]'>
            <div className='flex md:flex-row flex-col !justify-center !items-center'>
                <div className='md:!w-[50%] mb  -10 '>
                    {/* <div> */}
                    <iframe title='College Address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.6605565325303!2d80.1107810756295!3d12.865187217201242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52589404780505%3A0x9e77d9840f606368!2sSri%20Ramanujar%20Engineering%20College!5e0!3m2!1sen!2sin!4v1707571774926!5m2!1sen!2sin" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    {/* </div> */}
                </div>
                <div className='md:w-[50%] grid grid-cols-1'>
                    <ContactUsform border={"border"} borderColor={"border-richblack-800"} />
                </div>
            </div>
        </div>
    )
}

export default CollegeAddressContact