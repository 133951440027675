import moment from 'moment';
import React, { useState } from 'react'
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram, FaLinkedin } from "react-icons/fa";
import '../App.css';
import { Card, CardText, CardTitle, List, ListGroup, ListGroupItem } from 'reactstrap';


const Footer = () => {
    const [activeListItem, setActiveListItem] = useState(0);

    const handleListItemClick = (index) => {
        setActiveListItem(index);
    };


    const listItems = [
        {name: 'Home', href: '/'},
        {name: 'About Us', href: '/about-us'},
        {name: 'Privacy Policy', href: '/privacy-policy'},
        {name: 'Terms & Conditions', href: '/terms-condition'},
        {name: 'Cancellation & Refund', href: '/cancellation-policy'},
        {name: 'Shipping & Cancellation', href: '/shipping-policy'},
        {name: 'Contact Us', href: '/contact-us'},
   ]

    return (
        <div className='bg-richblue-700 '>
            <div className='flex w-11/12 mx-auto pt-4 flex-col md:flex-row flex-container'>
                <div className='w-full mx-auto flex flex-col lg:flex-row md:gap-3 justify-between items-center'>
                    <div className='md:w-[80%] w-full'>
                        <div className='w-full md:py-3 py-2 items-center flex flex-col md:flex-row md:py-5 md:pl-10'>
                            <img
                                style={{ marginTop: '-70px' }}
                                className="w-[110px]"
                                src={"/assets/logo.png"} alt="logo" />
                            <div>
                                <h3 className='md:!text-4xl md:w-[100%] leading-8 md:pt-[3%] !text-3xl pt-2 text-center pl-[10px] md:text-left text-[#f70301] font-bold'>Sri Ramanujar Engineering College</h3>
                                <p className='md:!text-[12px] !text-[45%] !leading-[18px] text-white pt-2 md:pt-0 text-center pb-3 md:w-[500px] mx-auto font-semibold'>APPROVED BY AICTE NEW DELHI  AND AFFILIATED TO ANNA UNIVERSITY <br /> ISO 9001:2015 CERTIFIED INSTITUTION & ACCREDITED BY NAAC </p>
                            </div>

                        </div>
                    </div>
                    <div className="information-div md:ml-10 md:mr-5 mt-3 md:mt-0 ">
                        <Card
                            body
                            className="my-2"
                            style={{
                                width: '18rem',
                                background: 'transparent'
                            }}
                        >
                            <CardTitle tag="h5" style={{ color: '#95BBFE', fontWeight: '' }}>
                                Information
                            </CardTitle>
                            <CardText>
                                <ListGroup flush>
                                    {listItems.map((item, index) => (
                                        <ListGroupItem
                                            tag='a'
                                            href={item.href}
                                            className={`custom-list-item text-white bg-transparent mx-2 ${activeListItem === index ? 'active' : 'text-white'}`}
                                            onClick={() => handleListItemClick(index)}
                                            key={index}
                                        >
                                            {item.name}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </CardText>
                        </Card>
                    </div>
                    <div className='lg:w-[20%]'>
                        <ul className='flex flex-row gap-3 pt-5 pb-4 items-center justify-center text-white'>
                            <li className='bg-[#2e4388] w-[40px] h-[40px] text-[18px] justify-center flex items-center rounded-md'>
                                <a href='https://instagram.com/sri_ramanujar' style={{ color: 'white' }}>
                                    <FaInstagram />
                                </a>
                            </li>
                            <li className='bg-[#2e4388] w-[40px] h-[40px] text-[18px] justify-center flex items-center rounded-md'>
                                <a href='https://facebook.com/ramanujar1426' style={{ color: 'white' }}>
                                    <FaFacebookF />
                                </a>
                            </li>
                            <li className='bg-[#2e4388] w-[40px] h-[40px] text-[18px] justify-center flex items-center rounded-md'>
                                <a href='https://twitter.com/srec_edu' style={{ color: 'white' }}>
                                    <FaTwitter />
                                </a>
                            </li>
                            <li className='bg-[#2e4388] w-[40px] h-[40px] text-[18px] justify-center flex items-center rounded-md'>
                                <a href='https://www.youtube.com/@sri_ramanujar' style={{ color: 'white' }}>
                                    <FaYoutube />
                                </a>
                            </li>
                            <li className='bg-[#2e4388] w-[40px] h-[40px] text-[18px] justify-center flex items-center rounded-md'>
                                <a href='https://linkedin.com/in/ramanujarcollege' style={{ color: 'white' }}>
                                    <FaLinkedin />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='!flex !mx-auto !h-full !text-center !w-11/12 md:flex-row flex-col !pb-[30px] text-white !justify-center !items-center'>
                <div className='w-full !pt-5 md:!pt-2 md:!text-xl text-xl'>© Copyright {moment().year()} Sri Ramanujar Engineering College. All rights reserved</div>
            </div>
        </div>
    )
}

export default Footer;
