import React from 'react'

const program = [
    {
        name:"Excellent Placement opportunities in many reputed multinational companies."
    },
    {
        name:"Extracurricular activities to unveil the young talents."
    },
    {
        name:"Government Scholarships for Eligible Students."
    },
    {
        name:"Separate Hostel facilities for Boys and Girls."
    },
    {
        name:"Modern Computer Lab with Internet."
    },
    {
        name:"Student Friendly Campus."
    },
    {
        name:"Canteen facilities."
    }
]

const ProgramHighlights = () => {
  return (
    <div>
        <ul className='pl-4'>
            {
                program.map((data, index)=>{
                    return(
                        <li className='list-disc pb-4 text-[18px]' key={index}>{data.name}</li>
                    )
                })
            }
        </ul>
    </div>
  )
}

export default ProgramHighlights