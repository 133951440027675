import React from 'react'

import PrivacyPolicy from '../components/PrivacyPolicy'

const Privacy = () => {
  return (
    <div className='pt-[50px] pb-[50px]'>
       
        <PrivacyPolicy/>
    </div>
  )
}

export default Privacy