import React from 'react';

function PrivacyPolicy() {
  return (
    <div style={{marginLeft:'400px',marginRight:'400px'}}>
      <h1 style={{fontWeight:'bolder',color:'#484f8d'}}>Privacy Policy</h1>
      <p style={{color:'#707dd0'}}>
        This privacy policy is intended to communicate how SREC uses and protects any information that you provide to SREC as part of online purchase through this website. SREC is committed to ensuring that your privacy is protected as per this policy. SREC may change this policy from time to time by updating this page. You are advised to check SREC’s privacy policy from time to time to get an update on changes, if any.
      </p>
      <h6>What We Collect</h6>
      <p style={{color:'#707dd0'}}>
        We may collect the following information:
      </p>
      <ul style={{ listStyleType: 'none', paddingLeft: 0 ,fontSize:'17px'}}>
  <li style={{ marginBottom: '8px' }}>
    <span style={{ color: '#007bff' }}>&bull;</span> Name
  </li>
  <li style={{ marginBottom: '8px' }}>
    <span style={{ color: '#007bff' }}>&bull;</span> Contact information including email address
  </li>
  <li style={{ marginBottom: '8px' }}>
    <span style={{ color: '#007bff' }}>&bull;</span> Demographic information such as postcode, preferences and interests, etc.
  </li>
  <li style={{ marginBottom: '8px' }}>
    <span style={{ color: '#007bff' }}>&bull;</span> Other information relevant to customer surveys and/or offers
  </li>
      </ul>
      <h6>What We Do With The Information We Gather</h6>
      <p style={{color:'#707dd0'}}>
        We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
      </p>
      <ul style={{ listStyleType: 'none', paddingLeft: 0,fontSize:'17px' }}>
  
  <li style={{ marginBottom: '8px' }}>
    <span style={{ color: '#007bff' }}>&bull;</span> Internal record keeping
  </li>
  <li style={{ marginBottom: '8px' }}>
    <span style={{ color: '#007bff' }}>&bull;</span> Maintaining Product Warranties
  </li>
  
  <li>
    <span style={{ color: '#007bff' }}>&bull;</span> We may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.
  </li>
</ul>

      <h6>Security</h6>
      <p style={{color:'#707dd0'}}>
        We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
      </p>
     
    </div>
  );
}

export default PrivacyPolicy;
