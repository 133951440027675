import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { getBusinessHeader } from "../config/header";
import { useNavigate } from 'react-router-dom';
import { Button, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { getDegree, getState } from '../api';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { setAuthenticate, setUser } from '../store/slices/user';

const Registerform = (props) => {
    const { border, borderColor, btnClick, focus, token } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fullNameRef = useRef(null);
    const customertoken = token

    const [emailOtpPrefix, setEmailOtpPrefix] = useState('')
    const [stateValue, setStateValue] = useState([]);
    const [degreeValue, setDegreeValue] = useState([]);
    const [courseValue, setCourseValue] = useState([]);
    const [phoneOTPBtn, setPhoneOTPBtn] = useState(false);
    const [emailOTPBtn, setEmailOTPBtn] = useState(false);
    const [phoneOtpValue, setPhoneOtpValue] = useState('');
    const [emailOtpValue, setEmailOtpValue] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        if (focus) {
            fullNameRef.current?.focus();
        }
    }, [focus])

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (customertoken) {
                    const stateRes = await getState({ skip: 0, limit: 0, sort: "", filter: '', token: customertoken });
                    const degreeRes = await getDegree({ skip: 0, limit: 0, sort: '{"display_order":1}', filter: '', token: customertoken });

                    if (stateRes && stateRes.result) setStateValue(stateRes.result);
                    if (degreeRes && degreeRes.result) setDegreeValue(degreeRes.result);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        dispatch(setAuthenticate(false))
    }, [dispatch, customertoken])

    const getCoursesList = async (degreeId) => {
        try {
            if (customertoken) {
                const coursesRes = await axios.get(`/business/api/v1/degrees/${degreeId}/courses`, getBusinessHeader(customertoken));
                if (coursesRes.data && coursesRes.data.result) {
                    setCourseValue(coursesRes.data.result);
                }
            }
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            countryCode: '+91',
            phone: '',
            phoneOTP: '',
            emailOTP: '',
            email: '',
            degree: '',
            state: '',
            course: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().test(value => value?.trim() !== ''),
            phone: Yup.string()
                .matches(/^[0-9]{10}$/, "Phone Number must be Numbers and equal to 10 digits"),
            phoneOTP: Yup.string()
                .matches(/^[0-9]{4}$/, 'Phone OTP must be only 4 digits'),
            emailOTP: Yup.string()
                .matches(/^[0-9]{6}$/, 'Phone OTP must be only 6 digits'),
            email: Yup.string().email(),
            degree: Yup.string(),
            state: Yup.string(),
            course: Yup.string(),
        }),
        onSubmit: async (values, helpers) => {
            try {
                if (customertoken) {
                    let bodyData = {
                        customer_loginid: values.phone,
                        customer_password: 'test123'
                    }
                    const data = {
                        "phone_verify_otp": phoneOtpValue
                    }

                    const emailOtpData = {
                        "email_verify_otp": emailOtpPrefix + '' + emailOtpValue
                    }
                    const phoneOtpVerify = await axios.post(`/business/api/v1/otp/verifyphone/${values.phone}`, data, getBusinessHeader(customertoken))
                    const emailOtpVerify = await axios.post(`/business/api/v1/otp/verifyemail/${values.email}`, emailOtpData, getBusinessHeader(customertoken))
                    //     .then(async (res) => {
                    if (phoneOtpVerify.status === 200 && emailOtpVerify.status === 200) {
                        toast.success("OTP Verified Successfully", { position: 'top-right' });
                        await axios.post("/business/api/v1/sales/customers", bodyData, getBusinessHeader(customertoken))
                        await axios.post("/business/api/v1/registers", values, getBusinessHeader(customertoken))
                            .then(resgisterRes => {
                                dispatch(setAuthenticate(true));
                                dispatch(setUser(resgisterRes.data));
                                toast.success("Registered successfully..!", { position: 'top-right' });
                                sessionStorage.setItem('registeredUser', JSON.stringify(resgisterRes.data));
                                setPhoneOTPBtn(false)
                                setEmailOTPBtn(false)
                                navigate("/admission-form")
                            }).catch(err => {
                                console.log(err);
                                toast.error(err.response && err.response.data ? err.response.data.ErrorDetail : 'Register failed..!', { position: 'top-right' })
                                console.log(err);
                            })
                    } else {
                        setPhoneOTPBtn(false);
                        setEmailOTPBtn(false);
                        toast.error('Invalid OTP!', { position: 'top-right' });
                    }
                }
                else {
                    toast.error('Please check all required fields are filled', { position: 'top-right' })
                    helpers.setSubmitting(false)
                }
            } catch (err) {
                toast.error(err.response && err.response.data ? err.response.data.ErrorDetail : "Application submitted failed..!", { position: "top-right" })
                console.error('Error at submitting form:', err);
                helpers.setSubmitting(false);
            }
        },
    });

    const handlePhoneOTP = async () => {
        if (formik.values.phone && customertoken) {
            setPhoneOTPBtn(true)
            axios.post(`/business/api/v1/otp/sendphone/${formik.values.phone}`, "", getBusinessHeader(customertoken))
                .then(res => {
                    toast.success("OTP sent please check your mobile", { position: 'top-right' });
                }).catch(err => {
                    toast.error(err.response && err.response.data ? err.response.data.ErrorDetail : 'Already registered..!', { position: 'top-right' })
                })
        }
        else {
            toast.error('Mobile number is required..!', { position: 'top-right' })
        }
    }

    const handleEmailOTP = async () => {
        if (formik.values.email && customertoken) {
            setEmailOTPBtn(true)
            axios.post(`/business/api/v1/otp/sendemail/${formik.values.email}`, "", getBusinessHeader(customertoken))
                .then(res => {
                    setEmailOtpPrefix(res.data.otp_prefix)
                    toast.success("OTP sent please check your Email", { position: 'top-right' });
                }).catch(err => {
                    toast.error(err.response && err.response.data ? err.response.data.ErrorDetail : 'Already registered..!', { position: 'top-right' })
                })
        }
        else {
            toast.error('Email Id is required..!', { position: 'top-right' })
        }
    }

    const handleDegreeChange = (e, formik) => {
        const selectedDegree = e.target.value;
        const degree = degreeValue.find((degree) => degree.degree_name === selectedDegree);
        if (degree) {
            formik.setValues({
                ...formik.values,
                degree_type: degree.degree_type
            });
            getCoursesList(degree.degree_id);
        }
    };

    useEffect(() => {
        if ((formik.values.name).trim() && formik.values.phone && formik.values.phoneOTP && formik.values.email && formik.values.emailOTP && formik.values.degree && formik.values.state && formik.values.course) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [formik.values]);

    return (
        <div className={`bg-white opacity-[1] md:w-[420px] w-[90%] mx-auto p-[15px] rounded-md ${border} ${borderColor}`}>
            <form onSubmit={formik.handleSubmit}>
                <div className='flex flex-row items-center justify-center'>
                    <h3 className='!text-2xl font-bold'>Register Now</h3>
                </div>
                <div className='w-full '>
                    <Input
                        innerRef={fullNameRef}
                        className='!w-full !h-[40px] border border-richblack-300 px-2 mb-2 outline-0'
                        type='text'
                        name='name'
                        placeholder='Full Name *'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.name}</span>
                </div>
                <div className='mb-2 outline-0 w-full'>
                    <InputGroup>
                        <InputGroupText
                            className='md-w-[20%] w-[15%]'
                            name='countryCode'
                        >
                            +91
                        </InputGroupText>
                        <Input
                            className='md-w-[50%] w-[45%] appearance-none'
                            type='text'
                            name='phone'
                            value={formik.values.phone}
                            placeholder='Contact Number *'
                            onKeyDown={evt => ['e', "E", '.', '-', '+'].includes(evt.key) && evt.preventDefault()}
                            onChange={formik.handleChange}
                        />
                        <Button className='md-w-[30%] w-[37%]' type='button' onClick={handlePhoneOTP} disabled={phoneOTPBtn}>Verify - OTP</Button>
                    </InputGroup>
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.phone}</span>
                </div>
                <div className='mb-2 outline-0'>
                    <Input
                        className='appearance-none'
                        type='text'
                        name='phoneOTP'
                        value={phoneOtpValue}
                        placeholder='Phone OTP *'
                        onKeyDown={evt => ['e', "E", '.', '-', '+'].includes(evt.key) && evt.preventDefault()}
                        onChange={(e) => {
                            setPhoneOtpValue(e.target.value)
                            formik.handleChange(e)
                        }}
                    />
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.phoneOTP}</span>
                </div>
                <div className='mb-2 outline-0'>
                    <InputGroup>
                        <Input
                            type='email'
                            name='email'
                            value={formik.values.email}
                            placeholder='Email Address *'
                            onChange={formik.handleChange}
                        />
                        <Button className='md-w-[30%] w-[37%]' type='button' onClick={handleEmailOTP} disabled={emailOTPBtn}>Verify - OTP</Button>
                    </InputGroup>
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.email}</span>
                </div>
                <div className='mb-2 outline-0'>
                    <InputGroup>
                        <InputGroupText
                            className='md-w-[20%] w-[15%]'
                            name='emailPrefix'
                        >
                            {emailOtpPrefix}
                        </InputGroupText>
                        <Input
                            className='appearance-none'
                            type='text'
                            name='emailOTP'
                            value={emailOtpValue}
                            placeholder='Email OTP *'
                            onKeyDown={evt => ['e', "E", '.', '-', '+'].includes(evt.key) && evt.preventDefault()}
                            onChange={(e) => {
                                setEmailOtpValue(e.target.value)
                                formik.handleChange(e)
                            }}
                        />
                    </InputGroup>
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.emailOTP}</span>
                </div>
                <div className='mb-2 outline-0'>
                    <Input
                        type='select'
                        name='degree'
                        value={formik.values.degree}
                        onChange={(e) => {
                            handleDegreeChange(e, formik);
                            formik.handleChange(e);
                        }}
                        placeholder='Degree *'
                    >
                        <option>Degree *</option>
                        {
                            degreeValue?.map((degrees, index) => (
                                <option key={index} value={degrees.degree_name}>
                                    {degrees.degree_name}
                                </option>
                            ))
                        }
                    </Input>
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.degree}</span>
                </div>
                <div className='mb-2 outline-0'>
                    <Input
                        type='select'
                        name='course'
                        value={formik.values.course}
                        onChange={formik.handleChange}
                        placeholder='Course *'
                    >
                        <option>Course *</option>
                        {
                            courseValue?.map((course, index) => {
                                return (
                                    <option key={index} value={course.course_name}>
                                        {course.course_name}
                                    </option>
                                )
                            })
                        }
                    </Input>
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.course}</span>
                </div>
                <div className='mb-2 outline-0'>
                    <Input
                        type='select'
                        name='state'
                        value={formik.values.state}
                        onChange={formik.handleChange}
                    >
                        <option>State *</option>
                        {
                            stateValue?.map((States, index) => {
                                return (
                                    <option key={index} value={States.state}>
                                        {States.state}
                                    </option>
                                )
                            })
                        }
                    </Input>
                    <span className='error-msg'>{formik.errors && formik.touched && formik.errors.state}</span>
                </div>
                <div className='w-full h-[40px] px-1 mb-5 outline-0 flex flex-row '>
                    <Label>
                        <span className='text-pink-200 font-bold'>NOTE :</span><br />
                        By clicking Proceed, I consent to receiving information related to my application.
                    </Label>
                </div>
                <div className='w-[70%] items-center justify-between mx-auto'>
                    <Button
                        className='bg-pink-500 text-richblack-5 w-full h-[40px]'
                        type='submit'
                        disabled={isButtonDisabled}
                    >
                        Proceed
                    </Button>
                </div>
                <div className='w-[70%] items-center justify-between text-center mx-auto mt-3'>
                    <h6><a href='/#' className='text-dark no-underline' onClick={btnClick}>Existing User? Login</a></h6>
                </div>
            </form >
        </div >
    )
}

export default Registerform