import React from 'react'
import HighlightText from './HighlightText'
import partner from '../data/partner.json'
import Slider from 'react-slick';

const Partners = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ],
    };

    return (
        <div className='flex flex-col w-11/12 mx-auto !pt-[50px] !pb-[50px]'>
            <div className='flex md:flex-row flex-col justify-center items-center'>
                <div className='md:w-[50%]'>
                    <h3 className='text-richblack-800 text-4xl font-bold'>More than <HighlightText text={"100+"} fontSize={"4xl"} /> companies are hiring our graduates through our Campus Placement Programmes!</h3>
                </div>
                <div className='md:w-[50%] w-10/12 mx-auto pt-[50px]' >
                    <Slider  {...settings}>
                        {
                            partner.map((partners, index) => {
                                return (
                                    <div key={index}>
                                        <img
                                            className='w-full h-auto'
                                            alt=''
                                            src={"/assets/" + partners.logo}
                                        />
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
            {/* <div className='flex md:flex-row flex-col gap-5 justify-evenly md:gap-10'>
                {
                    popularCourses.map((courses, index) => {
                        return <PopularCourse courses={courses} key={index} />
                    })
                }
            </div> */}
        </div>
    )
}

export default Partners