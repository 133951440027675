import React from 'react'
import {FaQuoteLeft, FaQuoteRight} from 'react-icons/fa';


const TestiCard = (props) => {
    let review = props.review;
  return (
    <div className='flex !pt-[35px] md:pt-0 flex-col md:relative '>
      <div className='absolute !top-[-3rem] md:top-[-7rem] z-[10] mx-auto'>
        <img 
        className="aspect-square rounded-full bg-richblue-5 !w-[140px] !h-[140px] !z-[25]"
        alt=''
        src={"/assets/"+review.image}
        />
        <div className='w-[140px] !h-[100px] bg-violet-500 rounded-full absolute
         !top-[-6px] !z-[-10] !left-[10px]'></div>
      </div>
      
      <div className='text-center !mt-7'>
        <p className='tracking-wider !font-bold !text-2xl !capitalize'>{review.name}</p>
        <p className='!text-violet-300 !uppercase !text-sm'>{review.job}</p>
      </div>


      <div className='text-violet-400 mx-auto mt-5'>
        <FaQuoteLeft/>
      </div>

      <div className='text-center mt-4 text-slate-500'>
        {review.text}
      </div>

      <div  className='text-violet-400 mx-auto mt-5'>
        <FaQuoteRight/>
      </div>
    </div>
  )
}

export default TestiCard
