import React from 'react'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
    const navigate = useNavigate();

    return (
        <div className=' bg-richblue-5 !h-full !w-full'>
            <div className='!w-11/12 !md:gap-1 !mx-auto !flex !flex-col md:!flex-row  !justify-center !items-center'>
                <div className='md:!w-[80%] !w-[100%]'>
                    <div className='!w-full md:py-3 py-2 items-center flex flex-col md:flex-row !md:py-5 md:!pl-10'>
                        <img
                            className="!w-[110px]"
                            src={"/assets/logo.png"}
                            onClick={() => navigate('/')}
                            style={{ cursor: 'pointer' }}
                            alt=''
                        />
                        <div>
                            <h3 className='md:!text-4xl md:!w-[100%] leading-10 md:pt-[3%] !text-3xl !text-center pl-[10px] md:!text-left !text-[#f70301] !font-bold'>Sri Ramanujar Engineering College</h3>
                            <p className='md:!text-[10px] !text-[55%] !leading-[18px] md:!pl-[4%] !pt-0 !text-center !pb-3 md:!w-[80%] !font-semibold'>APPROVED BY AICTE NEW DELHI  AND AFFILIATED TO ANNA UNIVERSITY<br /> ISO 9001:2015 CERTIFIED INSTITUTION & ACCREDITED BY NAAC</p>
                        </div>

                    </div>
                </div>

                <div className='md:w-[20%]'>
                    <div className='w-[150px] pb-3 md:pb-0 mx-auto'>
                        <img
                            className="!w-full !h-full object-cover"
                            alt=''
                            src={"/assets/tnea.png"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar