import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Video from "./Video";
import { useEffect, useState } from "react";
import '../App.css'
import CourseCard from "./CourseCard";
import { getCourse, getDegree } from "../api";


const Courses = ({ token }) => {
    const [courses, setCourses] = useState([]);
    const customerToken = token
    const [tabsName, setTabsName] = useState([]);
    const [currentTab, setCurrentTab] = useState(tabsName?.[0]?.degree_name);
    const [degreeId, setDegreeId] = useState('');
    const [currentCard, setCurrentCard] = useState(courses?.[0]?.course_name);

    const getCoursedata = async () => {
        if (degreeId && customerToken) {
            const courseRes = await getCourse({ degreeId: degreeId, limit: 0, skip: 0, sort: `{"display_order": 1}`, filter: '', token: customerToken })
            if (courseRes) {
                setCourses(courseRes.result)
            }
        }
    }

    useEffect(() => {
        const getData = async () => {
            try {
                if (customerToken) {
                    let payload = {
                        limit: 0,
                        skip: 0,
                        sort: `{"display_order": 1}`,
                        filter: '',
                        token: customerToken
                    }
                    const degreeResponse = await getDegree(payload);
                    if (degreeResponse) {
                        setTabsName(degreeResponse?.result);
                        setCurrentTab(degreeResponse?.result?.[0]?.degree_name)
                        const degreeId = degreeResponse?.result.map(data => data.degree_id)
                        setDegreeId(degreeId[0])
                    }
                }

            } catch (error) {
                console.log("error", error)
            }
        };
        getData();
    }, [customerToken])

    useEffect(() => {
        getCoursedata();
    }, [degreeId, customerToken]);


    const setMyCards = (value) => {
        setCurrentTab(value.degree_name);
        const result = courses && courses?.filter((data) => data.degree_id === value.degree_id);
        if (result) { 
            setCourses(result);
            setCurrentCard(result?.[0]?.course_name);
        }
    };

    return (
        <div className='flex flex-col'>
            <div className='flex md:flex-row flex-col'>
                <div className='md:w-[60%] productSlider mb-[50px] flex flex-col'>
                    <div className='flex flex-row'>
                        <div className='lg:w-[100%]'>
                            <div className='text-4xl title-font lg:w-[70%] text-richblack-800'>Your bright future is ready with us!</div>
                            <p className='text-lg text-richblack-800 py-5 pb-10'>Refine and elevate your core skills with our specialized course structures!</p>
                        </div>

                    </div>
                    <div className=" md:w-[100%] flex flex-col mx-auto w-[90%]">
                        {/* Tab Section */}
                        {/* Tabs Section */}
                        {/* className=" grid md:gap-10 w-[100%] grid-cols-4 justify-evenly -mt-5 mx-auto bg-[#2e4388] text-white p-1 rounded-full font-medium drop-shadow-[0_1.5px_rgba(255,255,255,0.25)]" */}
                        <div className={`grid md:gap-10 w-[100%] grid-cols-${tabsName.length} justify-evenly -mt-5 mx-auto bg-[#2e4388] text-white p-1 rounded-full font-medium drop-shadow-[0_1.5px_rgba(255,255,255,0.25)]`}>
                            {tabsName && tabsName?.map((ele, index) => {
                                return (
                                    <div
                                        className={` md:text-[20px] text-[18px] font-bold w-[100%] text-center flex flex-row items-center gap-2 ${currentTab === ele.degree_name
                                            ? "bg-[#445db0] text-white font-medium"
                                            : "text-white"
                                            } md:px-7 md:py-[7px] rounded-full items-center flex justify-center transition-all duration-200 cursor-pointer hover:bg-[#445db0] hover:text-richblack-5`}
                                        key={index}
                                        onClick={() => {
                                            setMyCards(ele);
                                            setDegreeId(ele.degree_id)
                                        }}

                                    >
                                        {ele.degree_name}
                                    </div>
                                );
                            })}
                        </div>
                        {/* <div className="hidden lg:block lg:h-[200px]"></div> */}
                        {/* Tab Section */}

                        {/* Card Group */}
                        <div className=" justify-center lg:gap-0 mt-6 flex flex-col lg:justify-between w-full text-black lg:mb-0 mb-3 px-3">
                            {courses && courses?.map((ele, index) => {
                                return (
                                    <CourseCard
                                        key={index}
                                        cardData={ele}
                                        currentCard={currentCard}
                                        setCurrentCard={setCurrentCard}
                                    />
                                );
                            })}
                        </div>
                        {/* Card Group */}

                    </div>

                </div>
                <div className='md:w-[50%] items-center flex flex-row justify-center'>
                    <div className="px-3 py-3 w-[100%] h-[450px] items-center justify-center mx-auto">
                        <Video />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Courses