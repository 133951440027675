import React from 'react';

const ContactUsform = ({ border, borderColor }) => {


    return (
        <div className={`bg-white opacity-[0.9] md:w-[420px] w-[90%] mx-auto p-[25px] rounded-md
                        ${border} ${borderColor}`} style={{marginBottom:'50px'}}
        >
               <h1 style={{ fontWeight: 'bolder', color: '#484f8d' }}>Contact Us </h1><br />
            <form>
                {/* <div className='text-center'>
                    <h3 className='!text-2xl font-bold'>Info</h3>
                </div> */}
               {/*  <div className='pb-3'>
                    <h6>044 – 22751380</h6>
                </div>
                <div className='flex flex-row'>
                    <h3 className='!text-2xl font-bold'>Principal / Manager :</h3>
                </div>
                <div className='pb-3'>
                    <h6>9444859455, 9840832639</h6>
                </div> */}
                <div className='flex flex-row'>
                    <h5 className='!text-2xl font-bold'>Mail :</h5>
                </div>
                <div className='pb-3'>
                    <h6>admissions@sriramanujar.ac.in</h6>
                </div>
                <div className='flex flex-row'>
                    <h5 className='!text-2xl font-bold'>Address :</h5>
                </div>
                <div className='pb-3'>
                    <h6>Kolapakkam, Vandalur, Chennai, Tamilnadu, India. 600127</h6>
                </div>
            </form >
        </div >
    )
}

export default ContactUsform