import React from 'react'

const Video = () => {

    const url = "/assets/SREC_DIGITAL_AD_03.mp4"

  return (
    <video  width="100%" className='h-full'  autoPlay loop muted>
      <source src={url} type="video/mp4" />
      Sorry, your browser doesn't support videos.
    </video>
  )
}

export default Video