import React from 'react'

import ShippingReturnPolicy from '../components/ShippingReturnPolicy'


const ShippingPolicy = () => {
  return (
    <div className='pt-[50px] pb-[50px]'>
   
        <ShippingReturnPolicy/>
    
    </div>
  )
}

export default ShippingPolicy