import React from "react";
import { Accordion } from "react-bootstrap";
import '../primaryComp.css';
import HTMLReactParser from "html-react-parser";


const CourseCard = ({ cardData, setCurrentCard }) => {


    return (
        <div
            className="w-[95%]"
            onClick={() => setCurrentCard(cardData?.course_name)}
        >
            <div className="local-bootstrap rounded-t-md mb-1 bg-white cardShadow  flex flex-col gap-1">
                <div className="bootstrap-iso">
                    <Accordion>
                        <Accordion.Item eventKey={cardData?.course_id}>
                            <Accordion.Header>
                                {(cardData?.title)}
                            </Accordion.Header>
                            <Accordion.Body className="richText">
                            {HTMLReactParser(cardData?.course_description)}
                                {/* {cardData?.course_description} */}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default CourseCard;
