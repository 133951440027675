
      import React from 'react';

function CancellationPolicy() {
  return (
    <div style={{ marginLeft: '400px', marginRight: '400px' }}>
      <h1 style={{ fontWeight: 'bolder', color: '#484f8d' }}>Admission Cancellation And Refund Policy</h1><br />

      <h6>Refund Terms:</h6><br />
      <ul style={{ color: '#707dd0', paddingBottom: '120px', listStyleType: 'disc' }}>
        <li>
          Any student who has sought admission to any program offered by Sri Ramanujar Engineering College, Chennai may apply for admission withdrawal.
        </li>
        <li>
          The admission cancellation request is to be addressed to the Dean / HOI of the college duly signed by the student and parent.
        </li>
        <li>
          Refunds would be on the admission fee only and not on the application fees.
        </li>
        <li>
          For Engineering & Management Programs: Fees paid will be refunded as per AICTE / UGC Norms.
        </li>
        <li>
          For Other Programmes: Fees paid will be refunded as per the UGC norms subject to directions of statutory bodies.
        </li>
      </ul>
    </div>
  );
}

export default CancellationPolicy;
