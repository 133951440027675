import React from 'react'
import TestiCard from './TestiCard';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useState } from 'react';


const Testimonials = (props) => {
    let reviews = props.reviews;
    const [index, setIndex] = useState(0);

    function leftShiftHandler() {
        if(index - 1 < 0) {
            setIndex(reviews.length - 1);
        }
        else {
            setIndex(index - 1);
        }
    }

    function rightShiftHandler() {
        if(index + 1 >=reviews.length) {
            setIndex(0);
        }
        else {
            setIndex(index+1);
        }

    }

  return (
    <div className='w-[85vw] relative md:w-[700px] bg-white border border-x-blue-100 flex flex-col justify-center items-center
    mt-10 p-10 transition-all duration-700 hover:shadow-xl rounded-md mx-auto'>

        <TestiCard review = {reviews[index]}></TestiCard>
     

      <div className='flex text-3xl mt-10 gap-3 text-violet-400 font-bold mx-auto'>
        <button 
        onClick={leftShiftHandler}
        className='cursor-pointer hover:text-violet-500 bg-pink-700 text-white px-2 rounded-full py-2 '>
            <FiChevronLeft/>
        </button>
        <button 
        onClick={rightShiftHandler}
        className='cursor-pointer hover:text-violet-500 bg-pink-700 text-white px-2 rounded-full py-2'>
            <FiChevronRight/>
        </button>
      </div>

      
    </div>
  )
}

export default Testimonials
