import React, { useEffect, useState } from 'react'
import HighlightText from '../components/HighlightText';
import Registerform from '../components/Registerform';
import Courses from '../components/Courses';
import courses from '../data/courses';
import ProgramHighlights from '../components/ProgramHighlights';
import Testimonials from '../components/Testimonials';
import reviews from "../data/testimonial";
import CTABtn from '../components/CTABtn';
import CTASection from '../components/CTASection';
import Partners from '../components/Partners';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Banner from '../data/Banner.json'
import Gallery from '../components/Gallery';
import CollegeAddressContact from '../components/CollegeAddressContact';
import Loginform from '../components/Loginform';
import axios from 'axios';

const Home = () => {
    var homesettings = {
        dots: false,
        infinite: true,
        loop: true,
        navigation: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false
    };

    const [loginForm, setLoginForm] = useState(false)
    const [focus, setFocus] = useState(false)
    const [customerToken, setCustomerToken] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                let bodyData = new FormData()
                bodyData.append("grant_type", 'client_credentials')
                bodyData.append("client_id", process.env.REACT_APP_CLIENT_ID)
                bodyData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET)
                const res = await axios.post('/business/api/v1/auth/token', bodyData)
                setCustomerToken(res.data.access_token)
                sessionStorage.setItem("customerToken", res.data.access_token)

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [])

    return (
        <div>
            {/* Section 1 */}
            <div className='lg:relative home-carousel md:h-[650px] section-1'>
                <div className='md:h-[650px]'>
                    <img className='w-full md:h-[650px] object-cover' src={"/assets/Banner1.jpg"} alt='Banner img' />
                </div>
                <div className='bg-richblack-5 md:bg-transparent pt-5 md:pt-0 pb-5 md:pb-0'>
                    <Slider className='md:!h-[650px] md:!absolute md:bg-transparent !top-[10%] !left-[3%] !w-[90%] md:!w-[500px]' {...homesettings}>
                        {
                            Banner.map((bannerContent, index) => {
                                return (
                                    <div key={index} className=' !bg-white !opacity-[0.9] !p-[50px] !top-[15%] md:!w-[450px] !w-full !left-[10%]'>
                                        <h3 className='!text-richblue-700 !font-bold !text-4xl'><HighlightText text={bannerContent.highLightText} /> {bannerContent.title} </h3>
                                        <p className='!text-md !text-richblack-700 !leading-5 !pt-3 !pb-7'>{bannerContent.content}</p>
                                        {bannerContent.degree ?
                                            <button type='button' onClick={() => setFocus(!focus)} className='!bg-pink-700 !text-white text-center !w-[200px] !px-3 !rounded-tr-2xl !rounded-bl-3xl !py-4 lg:!text-4xl !font-[500]'>{bannerContent.degree}</button>
                                            : ''}
                                        {bannerContent.btn ?
                                            <div className=''>
                                                <button type='button' onClick={() => setFocus(!focus)}
                                                    className='!bg-pink-700 text-center !text-white !w-[200px] !px-1 !rounded-tr-2xl !rounded-bl-3xl !py-4 lg:!text-4xl !font-[500]'
                                                    
                                                >
                                                    Apply Now
                                                </button>
                                            </div> : ''
                                        }
                                        <div className='!text-2xl !font-bold !pt-4'>{bannerContent.admission}</div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
                <div className='md:absolute md:pt-0 top-[4%] right-[10%] bg-richblack-5  md:bg-transparent ' id='register-card'>
                    {loginForm ?
                        <Loginform border={"border"} borderColor={"border-richblack-800"} btnClick={() => setLoginForm(false)} token={customerToken}/>
                        :
                        <Registerform border={"border"} borderColor={"border-richblack-800"} focus={focus} btnClick={() => setLoginForm(true)} token={customerToken}/>
                    }
                </div>
            </div>

            <CTABtn />

            {/* Section 2 - Courses Card with tab */}
            <div className='w-full bg-gray mx-auto pt-[50px]'>
                <div className='w-11/12 mx-auto'>
                    <Courses token={customerToken}/>
                </div>
            </div>

            {/* CTA Section */}
            <div>
                <CTASection />
            </div>

            {/* Section 3 - Partners */}
            <div className=''>
                <Partners />
            </div>

         
            {/* Section 4 - ProgramHighlights */}
            <div className='flex md:flex-row flex-col pt-[50px] w-10/12 relative mx-auto'>
                <div className='md:w-[50%]'>
                    <img className='w-full object-cover lg:h-[100%]' src={"/assets/side-img.jpg"} alt=''/>
                </div>
                <div className='flex flex-col text-richblack-5 bg-[#2e4388] md:pr-[5%] pt-[5%] pb-[10%] pr-[10%] pl-[10%] md:pt-[1%] md:pb-[2%] md:pl-[2%] md:w-[50%]'>
                    <h3 className='text-4xl title-font lg:w-[70%] text-richblack-50 pt-5 pb-10'>Campus Highlights</h3>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> */}
                    <ProgramHighlights />
                </div>
            </div>

            {/* Gallery */}
            <div className='w-10/12 mx-auto pt-[50px]'>
                <h3 className='text-4xl title-font lg:w-[100%] text-center text-richblack-800 pb-[50px]'>Gallery</h3>
                <Gallery />
            </div>

            {/* Section 5 - Testimonials*/}
            <div className='w-11/12 mx-auto mt-[50px] mb-[100px]'>
                <h3 className='text-4xl title-font lg:w-[100%] text-center text-richblack-800 pb-[50px]'>Testimonials</h3>
                <Testimonials reviews={reviews} />
            </div>

            {/* College Map and Address */}
            <div>
                <CollegeAddressContact />
            </div>

        </div>
    )
}

export default Home