import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Admission from './pages/Admission';
import Footer from './components/Footer';
import Heartbeat from './pages/Heartbeat';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './ProtectedRoute';
import TermsCondition from './pages/TermsAndCondition';
import ShippingPolicy from './pages/Shipping';
import Privacy from './pages/Privacy';
import CancellationPolicys from './pages/CancelltionPolicy';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import PaymentSuccess from './pages/PaymentSuccess';

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/admission-form" element={
          <ProtectedRoute>
            <Admission />
          </ProtectedRoute>}
        />
        <Route path="/heartbeat" element={<Heartbeat />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/shipping-policy" element={<ShippingPolicy />} />
        <Route path="/cancellation-policy" element={<CancellationPolicys />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/payment-success" element={
          <ProtectedRoute>
            <PaymentSuccess />
          </ProtectedRoute> 
        }
        />

      </Routes>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default App;
