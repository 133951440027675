import React from 'react'
import gallery from '../data/Gallery.json'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Gallery = () => {
    var Gallerysettings = {
        dots: false,
        infinite: true,
        loop: true,
        navigation: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <div className=''>
                <Slider className=' md:bg-transparent !top-[10%] !left-[3%] w-full' {...Gallerysettings}>
                    {
                        gallery.map((imgages, index) => {
                            return (
                                <div key={index}>
                                    <img className='w-full h-auto' src={"/assets/" + imgages.imgLink} alt=''/>
                                </div>
                            )
                        })
                    }
                </Slider>

            </div>
        </div>
    )
}

export default Gallery