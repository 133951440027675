import React from 'react'
import CancellationPolicy from '../components/Cancellation'

const CancellationPolicys = () => {
  return (
    <div className='pt-[50px] pb-[50px]'>
        <CancellationPolicy/>

    </div>
  )
}

export default CancellationPolicys