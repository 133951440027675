import React from 'react';

function ShippingReturnPolicy() {
  return (
    <div style={{ marginLeft: '400px', marginRight: '400px' }}>
      <h1 style={{ fontWeight: 'bolder', color: '#484f8d' }}>Shipping Policy</h1>
      <br />

      <p style={{ color: '#707dd0' }}>
        We supply all study materials and books for each academic year as requested by our fellow students.
        Our college's shipping policy ensures that orders are processed efficiently and within 1-2 business days.
        Refunds are processed quickly, and some things, such as digital downloads and bespoke orders, may not be returnable      </p>
      <h6>Returns</h6>
      <p style={{ color: '#707dd0' ,paddingBottom:'70px'}}>
        We accept returns within 30 days of purchase, requiring items to be in original condition and packaging.
        Initiate a return by contacting management with your order number.
        Return shipping is the student's responsibility unless it's our error or a defective package.
        Refunds are processed upon receiving and inspecting the return, appearing in your account in several business days.
        Non-returnable items include digital downloads, custom orders, and opened or used items.      </p>
      {/* <h6>Delivery Time</h6>
      <p style={{color:'#707dd0'}}>
        SREC takes approximately around 7-10 days to deliver the product to your location after you complete the order. However in extreme cases, it may take longer to ship the product. In such cases you will be intimated well in advance.
      </p> */}

    </div>
  );
}

export default ShippingReturnPolicy;
