import React from 'react'

const CTASection = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: Adds smooth scrolling behavior
    });
  };

  return (
    <div className='!flex !flex-col md:!flex-row !w-full lg:!h-[170px]' style={{ backgroundColor: '#2D4287'}}>
        <div className='!flex !w-10/12 !mx-auto !justify-around !flex-col md:!flex-row !items-center sm:!p-5 !gap-10'>
            <div className='!pt-5 md:pt-0'><img src={"/assets/cta-icon1.png"} alt="right-img" /></div>
            <div className='text-richblack-5 text-4xl text-center md:text-4xl'>Join SREC to shape your career with expert mentorship!</div>
            <div className='!pb-5 md:!pb-0'>
                <button className='text-richblack-5 text-[25px] !px-4 !py-2 rounded-md' style={{ backgroundColor: '#95BBFE'}} onClick={scrollToTop}>Apply Now</button>
            </div>
        </div>
    </div>
  )
}

export default CTASection