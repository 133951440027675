import React from 'react'
import { GrContact } from "react-icons/gr";
import { Link } from 'react-router-dom';

const CTABtn = () => {

    const handleButtonClick = () => {
        const isMobile = window.innerWidth <= 768;
        if (isMobile) {
            const registerCard = document.getElementById('register-card');
            if (registerCard) {
                registerCard.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <div>
            <Link to='/#' className='apply-now-btn flex flex-row justify-center gap-2 items-center' onClick={handleButtonClick}>
                <GrContact />
                <div>Apply Now </div>
            </Link>
        </div>
    )
}

export default CTABtn