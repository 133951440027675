import React from 'react'
import AdmissionForm from '../components/admission/AdmissionForm'

const Admission = () => {
  return (
    <div className='pt-[50px] pb-[50px]'>
        <AdmissionForm/>
    </div>
  )
}

export default Admission